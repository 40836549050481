import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Container from "../components/container";
import PortableText from "../components/portableText";
import { Figure } from "../components/Figure";
import SearchUI from "../components/search/SearchUI";

export const query = graphql`
  query SymbolTemplateQuery($id: String!) {
    symbol: sanitySymbol(id: { eq: $id }, hidden: { eq: false }) {
      id
      title
      category
      mainImage {
        ...SanityImage
        alt
        caption
      }
      description: _rawDescription(resolveReferences: { maxDepth: 5 })
    }
    symbolFr: sanitySymbol(id: { eq: $id }, hidden: { eq: false }) {
      id
      title: title_french
      category
      mainImage {
        ...SanityImage
        alt: alt_french
        caption: caption_french
      }
      description: _rawDescriptionFrench(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const SymbolTemplate = ({ data, pageContext }) => {
  const { lang } = pageContext;
  const symbol = lang === "en" ? data?.symbol : data?.symbolFr;
  if (!symbol) return null;

  const { title = "Untitled symbol", description } = symbol;

  return (
    <>
      <SEO title={title} />
      <SearchUI isFrench={lang === "fr"} />
      <article>
        <Container noPad>
          <div className="prose dark:prose-invert prose-lg max-w-full md:py-12">
            <h2>{title}</h2>
            <div className="flex md:flex-row-reverse md:justify-end items-center md:items-start flex-col gap-x-5 lg:gap-x-10">
              <Figure value={symbol.mainImage} />
              <div className="max-w-prose section-content-wrapper grid">
                <PortableText blocks={description} />
              </div>
            </div>
          </div>
        </Container>
      </article>
    </>
  );
};

export default SymbolTemplate;
